<template>
  <div style="text-align: left">预约信息</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="手机号">
      <el-input
        v-model="seachForm.cellphone"
        clearable
        size="small"
        placeholder="请输入手机号"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>
  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 15px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="expertName" label="专家姓名" width="150px">
    </el-table-column>
    <el-table-column prop="createDate" label="预约日期" width="150px">
    </el-table-column>
    <el-table-column prop="userName" label="联系人" width="150px">
    </el-table-column>
    <el-table-column prop="cellphone" label="联系电话" width="150px">
    </el-table-column>
    <el-table-column prop="content" label="需求描述" width="300px">
    </el-table-column>
    <el-table-column prop="address" label="操作">
      <template #default="scope">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
          "
        >
          <!-- <el-button
            type="text"
            @click="editprofession(scope.row)"
            class="marginleft10"
            >查看</el-button
          > -->
          <el-button
            type="text"
            @click="deleteprofession(scope.row)"
            class="marginleft10"
            >删除</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      seachForm: {
        cellphone: '',
      },
      copyform: {
        timepriod: '',
        startDate: '',
        endDate: ''
      },
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() + 24 * 60 * 60 * 1000)
          end.setTime(start.getTime() + 7 * 24 * 60 * 60 * 1000)
          // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }],
      dialogVisible: false,
      tableData: [],
      copyrowId: '',
      preList: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '专家管理',
        index: '/professionmange'
      },
      {
        name: '专家预约',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    // this.getprofessionpre().then(() => {
    this.getTableData(1)
    // })
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/appointment/appointments/1' + '?pageSize=' + val + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone)
      const res = await this.$http.get('/nktdec/appointment/appointments/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    // async getprofessionpre() {
    //   const res = await this.$http.get('/nktdec/appointment/appointments/1?pageSize=100')
    //   this.preList = res.data.content
    // },
    async getTableData(pageno) {
      const res = await this.$http.get('/nktdec/appointment/appointments/' + pageno)
      console.log(res.data.content)
      this.tableData = res.data.content
      console.log(this.preList)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      //   this.tableData.forEach(item => {
      //     // console.log(this.preList.some(preitem => preitem.id === item.id))
      //     if (this.preList.some(preitem => preitem.id === item.id)) {
      //       item.preList = []
      //       item.preList.push(this.preList.find(preitem => preitem.id === item.id))
      //     }
      //   })
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.cellphone.trim() === '' ? '' : 'cellphone=' + this.seachForm.cellphone)
      console.log(str)
      const res = await this.$http.get('/nktdec/appointment/appointments/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/appointment/appointments/1')
      console.log(res.data)
      this.seachForm.cellphone = ''
      this.tableData = res.data.content
    },
    addTeam() {
      this.$router.push('/changeprofess/true')
      console.log('正在新增活动')
    },

    copyteamact(row) {
      this.copyrowId = row.id
      this.dialogVisible = true
    },
    stopteamwork(row) {
      if (row.TeamStatus === 'start') {
        console.log('已中止')
      } else if (row.TeamStatus === 'waitstart') {
        console.log('已取消')
      }
    },
    deleteprofession(row) {
      this.$confirm('此操作将永久删除该预约信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/appointment/appointment/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.getTableData(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    editprofession(row) {
      this.$store.commit('setProfessionInfo', row)
      this.$router.push('/changeprofess/false')
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    }
  }
}
</script>
<style scoped>
</style>
