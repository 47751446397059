<template>
  <div style="text-align: left">编辑专家信息</div>
  <el-form
    :model="professionForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="专家姓名" size="small" prop="name" style="width: 50%">
      <div style="display: flex">
        <el-input
          style="width: 250px"
          clearable
          v-model="professionForm.name"
          placeholder="请输入专家姓名！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="专家照片" class="goodpic" style="width: 50%">
      <el-upload
          :action="UploadUrl"
         :headers="{
           authorization: Token
         }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
         :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=""
        :file-list="teamlist"
        :on-success="filechange"
        :limit="2"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >第一张为专家介绍页图片（建议宽高比1：1），第二张为专家轮播图片、图片大小200k为宜 , 最多上传2张,单张图片不超过2M</span
      >
    </el-form-item>
    <el-form-item
      label="手机号码"
      size="small"
      prop="cellphone"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 250px"
          clearable
          v-model="professionForm.cellphone"
          placeholder="请输入手机号码！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="领域" size="small" prop="domain" style="width: 50%">
      <div style="display: flex">
        <el-input
          style="width: 250px"
          clearable
          v-model="professionForm.domain"
          placeholder="请输入领域！"
        ></el-input>
      </div>
      <div style="display: flex">
        <span
          style="color: rgba(0, 0, 0, 0.45); text-align: left; margin-top: 5px"
          >多领域中文逗号隔开例如：A，B</span
        >
      </div>
    </el-form-item>

    <el-form-item
      label="专家简介"
      size="small"
      prop="profile"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 250px"
          clearable
          v-model="professionForm.profile"
          placeholder="请输入专家简介！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="专家描述" prop="profile" style="width: 80%">
      <vue-ueditor-wrap
        v-model="professionForm.description"
        :config="editorConfig"
        editor-id="professionInfo"
      ></vue-ueditor-wrap>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      professionForm: {
        name: '',
        cellphone: '',
        profile: '',
        domain: '',
        description: '',
        pictureUrl1: '',
        pictureUrl2: '',
        pictureUrl3: '',
        pictureUrl4: '',
        pictureUrl5: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入专家姓名！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        cellphone: [
          { required: true, message: '请输入专家手机号码！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入专家简介！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      baseUrl: '',
      teamlist: [],
      piclist: [],
    }
  },
  methods: {
        handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handleRemove(file) {
      var i = this.teamlist.findIndex(item => item.url === file.url)
      this.teamlist.splice(i, 1)
      console.log(file.url.split('/'))
      var tmpArr = file.url.split('/')
      var j = this.piclist.findIndex(item => item === tmpArr[tmpArr.length - 1])
      this.piclist.splice(j, 1)
      console.log('绑定list', this.teamlist)
      console.log('提交数组', this.piclist)
    },
    async onsubmit() {
      if (this.piclist.length < 1) {
        this.$message.error('请上传照片！')
        return false
      }
      const data = {}
      if (this.$route.params.isadd === 'false') {
        data.id = this.professionForm.id
        data.name = this.professionForm.name
        data.cellphone = this.professionForm.cellphone
        data.profile = this.professionForm.profile
        data.description = this.professionForm.description
        data.pictureUrl1 = this.piclist[0]
        data.pictureUrl2 = this.piclist[1] ? this.piclist[1] : ''
        data.pictureUrl3 = this.piclist[2] ? this.piclist[2] : ''
        data.pictureUrl4 = this.piclist[3] ? this.piclist[3] : ''
        data.pictureUrl5 = this.piclist[4] ? this.piclist[4] : ''
        data.domain = this.professionForm.domain
      } else {
        data.name = this.professionForm.name
        data.cellphone = this.professionForm.cellphone
        data.profile = this.professionForm.profile
        data.description = this.professionForm.description
        data.pictureUrl1 = this.piclist[0]
        data.pictureUrl2 = this.piclist[1] ? this.piclist[1] : ''
        data.pictureUrl3 = this.piclist[2] ? this.piclist[2] : ''
        data.pictureUrl4 = this.piclist[3] ? this.piclist[3] : ''
        data.pictureUrl5 = this.piclist[4] ? this.piclist[4] : ''
        data.domain = this.professionForm.domain
      }
      console.log(data)
      const res = await this.$http.post('/nktdec/expertinfo/expertinfos', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('编辑成功！')
        this.$router.push('/professionlist')
      }
    },
    async filechange(res,file, fileList) {
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      console.log(res)
      this.teamlist.push({
        url: this.imgBaseUrl + res.result
      })
      if (!(this.piclist.some(item => item === res.result))) {
        this.piclist.push(res.result)
      } else {
        var i = this.piclist.findIndex(item => item === res.result)
        this.piclist.splice(i, 1, res.result)
      }
    },
  },
  computed: {
    ProfessionInfo() {
      return this.$store.state.ProfessionInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '专家管理',
        index: '/professionmange'
      },
      {
        name: '专家列表',
        index: '/professionlist'
      }, {
        name: '编辑信息',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    if (this.$route.params.isadd === 'false') {
      this.professionForm = this.ProfessionInfo
      console.log(this.professionForm)
      this.teamlist.push({
        url: this.imgBaseUrl + this.professionForm.pictureUrl1
      })
      this.piclist.push(this.professionForm.pictureUrl1)
      if (this.professionForm.pictureUrl2) {
        this.teamlist.push({
          url: this.imgBaseUrl + this.professionForm.pictureUrl2
        })
        this.piclist.push(this.professionForm.pictureUrl2)
      }
      if (this.professionForm.pictureUrl3) {
        this.teamlist.push({
          url: this.imgBaseUrl + this.professionForm.pictureUrl3
        })
        this.piclist.push(this.professionForm.pictureUrl3)
      }
      if (this.professionForm.pictureUrl4) {
        this.teamlist.push({
          url: this.imgBaseUrl + this.professionForm.pictureUrl4
        })
        this.piclist.push(this.professionForm.pictureUrl4)
      }
      if (this.professionForm.pictureUrl5) {
        this.teamlist.push({
          url: this.imgBaseUrl + this.professionForm.pictureUrl5
        })
        this.piclist.push(this.professionForm.pictureUrl5)
      }
      console.log(this.teamlist)
    }
  },
}
</script>
<style scoped>
</style>
